import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import carrierMasterCarrier from "./service-level-service";
import commonAPIService from "../../common/js/common-api-service";
import carrierMasterComp from "../../company/carrier-master-comp.vue";
import AddFavourite from "../../utility/js/addFav";
export default {
  data: () => ({
    name: "ServiceLevelEdit",
    backSlash: true,
    refreshOutlined: true,
    selectedProject: "",
    selectedBusiness: "",
    selectedOrderType: "",
    userId: EncryptUtility.localStorageDecrypt("userID"),
    serviceLevelsData: [],
    panel: 0,
    serviceLevelCode: "",
    activeServiceLevel: true,
    domesticServiceLevel: true,
    freightCarrierServiceLevel: true,
    orderReqCarrierCode: "",
    orderReqServiceLevelCode: "",
    activateCarrWebServ: true,
    apiinterfaceSetting: "",
    outboundAccNum: "",
    outboundAccName: "",
    selectedAccountOut: "",
    selectedAccountRet: "",
    accountData: [],
    upsAPIThirdParAccCountryCode: "",
    upsAPIThirdPartyPostalCode: "",
    upsAPIThirdPartyPostalCodeRet: "",
    upsAPIThirdParAccCountryCodeRet: "",
    thirdPartyAccNoOut: "",
    thirdPartyBillOut: true,
    returnAccNum: "",
    thirdPartyAccNoRet: "",
    thirdPartyBillRet: true,
    returnAccName: "",
    pass: null,
    sl_id: null,
    accountInfo: "",
    ei_on: null,
    showUPSDetails: false,
    activeServiceLevel_Enabled: false,
    domesticServiceLevel_Enabled: false,
    freightCarrierServiceLevel_Enabled: false,
    serviceLevelCode_Enabled: false,
    activateCarrWebServ_Enabled: false,
    apiinterfaceSetting_Enabled: false,
    orderReqCarrierCode_Enabled: false,
    orderReqServiceLevelCode_Enabled: false,
    outboundAccNum_Enabled: false,
    outboundAccName_Enabled: false,
    returnAccNum_Enabled: false,
    returnAccName_Enabled: false,
    thirdPartyAccNoOut_Enabled: false,
    thirdPartyAccNoRet_Enabled: false,
    upsAPIThirdPartyPostalCode_Enabled: false,
    upsAPIThirdPartyPostalCodeRet_Enabled: false,
    upsAPIThirdParAccCountryCode_Enabled: false,
    upsAPIThirdParAccCountryCodeRet_Enabled: false,
    newAccount: false,
    required: [(v) => !!v || "Field is required"],
    accountName: "",
    accAllowforMultiProj: false,
    accountNumber: "",
    upsPortalName: "",
    upsPortalPass: "",
    upsAccessLicense: "",
    title: "",
    firstName: "",
    lastName: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    selectedCountry: "",
    countryData: [],
    phone: "",
    email: "",
    serviceLevelDetails: [],
    service_pw: "",
    service_pw_rtn: "",
    default_sl: null,
    addAccountFormValid: false,
    serviceLevelEditValid: false,
    accountId: null,
    accountDetailsOut: [],
    accountDetailsRet: [],
    companyAddress: "",
    accAddress1: "",
    accAddress2: "",
    accCity: "",
    accState: "",
    accZip: "",
    accCountry: "",
    companyAddressRet: "",
    accAddress1Ret: "",
    accAddress2Ret: "",
    accCityRet: "",
    accStateRet: "",
    accZipRet: "",
    accCountryRet: "",
    accountSelectedDetailsOut: false,
    accountSelectedDetailsRet: false,
    retOrOutbound: "",
    showNonUPSDetails: false,
    showFedExDetails: false,
    showUSPSDetails: false,
    serviceLevel: "",
    isPasswordShow: false,
    isPasswordShowRet: false,
    isPasswordShowUPS: false,
    actionList: [],
    mainPage: "",
    subPage: "",
    back: true,
    subPageUrl: "",
    pageUrl: "",
    appPageName: "",
    backSlashParent: true,
    max100Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 100 || "Max Length must be 100 characters or less"],
    max5Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 5 || "Max Length must be 5 characters or less"],
    max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Max Length must be 50 characters or less"],
    max200Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 200 || "Max Length must be 200 characters or less"],
    max60Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 60 || "Max Length must be 60 characters or less"],
    max30Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 30 || "Max Length must be 30 characters or less"],
    max30NoReqRule: [(v) => (v || "").length <= 30 || "Max Length must be 30 characters or less"],
    max20Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 20 || "Max Length must be 20 characters or less"],
    max20NoReqRule: [(v) => (v || "").length <= 20 || "Max Length must be 20 characters or less"],
    max36Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 36 || "Max Length must be 36 characters or less"],
    accDetOutBound: [],
    accDetReturn: [],
    accountAction: "",
    popupTitle: "",
    CXValid: false,
    webServiceAPIValid: false,
    nonUPSValid: false,
    UPSValid: false,
    fedExValid: false,
    USPSValid: false,
    formValidated: false,
    showAPISection: false,
    compName: "",
    carrierCompCalled: false,
    singleCarrierDetUPSOut: [],
    singleCarrierDetFedExOut: [],
    singleCarrierDetUPSRet: [],
    singleCarrierDetFedExRet: [],
    textAPIIntegration: "",
    carrierShortName: "",
    carrierKey: 0,
    accountType: "",
    newCarrierID: 0,
    newAccOutbound: false,
    newAccReturn: false,
  }),
  async created() {
    let dataSimple = await AddFavourite.getPageAction(this.userId, "app-service-level");
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = dataSimple.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.subPageUrl = data.subPageUrl;
    this.appPageName = data.appPageName;
    let countryStateData = await commonAPIService.countryListNew(this.userId, "", 1, "get");
    this.countryData = countryStateData.CountryInfo;
  },
  mounted() {
    //Route Params
    this.serviceLevelsData = JSON.parse(atob(this.$route.params.serviceLevelsEdit));
    this.selectedProject = this.serviceLevelsData.project;
    this.selectedBusiness = this.serviceLevelsData.business;
    this.selectedOrderType = this.serviceLevelsData.ordertype;
    this.serviceLevel = this.serviceLevelsData.serviceLevel;
    this.getServiceLevelDetails();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.serviceLevelCode = "";
      this.serviceLevelCode_Enabled = false;
      this.activeServiceLevel = false;
      this.activeServiceLevel_Enabled = false;
      this.domesticServiceLevel = false;
      this.domesticServiceLevel_Enabled = false;
      this.freightCarrierServiceLevel = false;
      this.freightCarrierServiceLevel_Enabled = false;
      this.orderReqCarrierCode = "";
      this.orderReqCarrierCode_Enabled = false;
      this.orderReqServiceLevelCode = "";
      this.orderReqServiceLevelCode_Enabled = false;
      this.activateCarrWebServ = false;
      this.activateCarrWebServ_Enabled = false;
      this.apiinterfaceSetting = "";
      this.apiinterfaceSetting_Enabled = false;
      this.showUPSDetails = true;
      this.outboundAccName = "";
      this.outboundAccName_Enabled = false;
      this.returnAccName = "";
      this.returnAccName_Enabled = false;
      this.outboundAccNum = "";
      this.outboundAccNum_Enabled = false;
      this.selectedAccountOut = "";
      this.thirdPartyBillOut = false;
      this.thirdPartyAccNoOut = "";
      this.thirdPartyAccNoOut_Enabled = false;
      this.upsAPIThirdParPostal = "";
      this.upsAPIThirdParAccCountryCode = "";
      this.upsAPIThirdPartyPostalCode_Enabled = false;
      this.returnAccNum = "";
      this.returnAccNum_Enabled = false;
      this.returnAccNum = "";
      this.returnAccNum_Enabled = false;
      this.selectedAccountRet = "";
      this.thirdPartyBillRet = false;
      this.thirdPartyAccNoRet = "";
      this.thirdPartyAccNoRet_Enabled = false;
      this.upsAPIThirdPartyPostalCodeRet = "";
      this.upsAPIThirdPartyPostalCodeRet_Enabled = false;
      this.upsAPIThirdParAccCountryCodeRet = "";
      this.upsAPIThirdParAccCountryCodeRet_Enabled = false;
      this.carrierSelected = true;
      this.isPasswordShow = false;
      this.isPasswordShowRet = false;
      this.isPasswordShowUPS = false;
      this.showAPISection = false;
      this.compName = "";
      this.carrierCompCalled = false;
      this.singleCarrierDet = [];
      this.textAPIIntegration = "";
      this.closeDialog();
      this.serviceLevelsData = [];
      this.serviceLevelsData = JSON.parse(atob(this.$route.params.serviceLevelsEdit));
      this.selectedProject = this.serviceLevelsData.project;
      this.selectedBusiness = this.serviceLevelsData.business;
      this.selectedOrderType = this.serviceLevelsData.ordertype;
      this.serviceLevel = this.serviceLevelsData.serviceLevel;
      this.carrierShortName = "";
      this.carrierKey = 0;
      this.newAccOutbound = false;
      this.newAccReturn = false;
      this.getServiceLevelDetails();
      this.$refs.EditServiceLevelForm.resetValidation();
      if (this.newAccount) this.$refs.AddAccountForm.resetValidation();
      this.$refs.carrierXilyticInfoForm.resetValidation();
      this.$refs.webServiceAPIForm.resetValidation();
      this.$refs.UPSForm.resetValidation();
      this.$refs.nonUPSForm.resetValidation();
      this.$refs.fedExForm.resetValidation();
      this.$refs.USPSForm.resetValidation();
    },
    //Getting data based on the service level selected
    async getServiceLevelDetails(newCarrierID) {
      this.pass = 2;
      this.sl_id = 0;
      this.accountDetailsOut = [];
      this.accountDetailsRet = [];
      let obj = {
        User_Id: parseInt(this.userId),
        bu_id: parseInt(this.serviceLevelsData.bu_id),
        sbu_id: parseInt(this.serviceLevelsData.sbu_id),
        sl_id: parseInt(this.sl_id),
        slbu_id: parseInt(this.serviceLevelsData.slbu_id),
        pass: parseInt(this.pass),
        ord_code: this.serviceLevelsData.ord_code,
      };
      let data = await carrierMasterCarrier.getServiceLvlDetails("post", obj);
      if (data.message != "NA") {
        if (newCarrierID !== null && newCarrierID !== undefined) {
          this.accountData = data.AccountInfo;
          this.newCarrierID = newCarrierID;
          if (this.accountType == "OutBound") {
            this.accountDetailsOut = this.accountData.filter((obj) => obj.account_id === this.newCarrierID);
            this.selectedAccountOut = this.accountDetailsOut[0].account_id;
            let accountSet = true;
            this.accountSelectedOut(accountSet);
            let obj = {
              UserId: this.userId,
              pass: 4,
              account_id: parseInt(this.newCarrierID),
              acct_name: "",
              carrier_key: 0,
              proj_key: 0,
              bu_key: 0,
              accountno: "",
              client_id: "",
              client_secret: "",
              status: true,
            };
            let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
            if (data.message != "NA") {
              this.accountSelectedDetailsOut = true;
              if (this.showUPSDetails) {
                this.singleCarrierDetUPSOut = data[0];
                this.outboundAccName = this.singleCarrierDetUPSOut.FriendlyName;
                this.outboundAccNum = this.singleCarrierDetUPSOut.accountno;
              } else if (this.showFedExDetails) {
                this.singleCarrierDetFedExOut = data[0];
                this.outboundAccName = this.singleCarrierDetFedExOut.FriendlyName;
                this.outboundAccNum = this.singleCarrierDetFedExOut.accountno;
              }
            }
          } else if (this.accountType == "Return") {
            this.accountDetailsRet = this.accountData.filter((obj) => obj.account_id === this.newCarrierID);
            this.selectedAccountRet = this.accountDetailsRet[0].account_id;
            let accountSet = true;
            this.accountSelectedRet(accountSet);
            let obj = {
              UserId: this.userId,
              pass: 4,
              account_id: parseInt(this.newCarrierID),
              acct_name: "",
              carrier_key: 0,
              proj_key: 0,
              bu_key: 0,
              accountno: "",
              client_id: "",
              client_secret: "",
              status: true,
            };
            let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
            if (data.message != "NA") {
              this.accountSelectedDetailsRet = true;
              if (this.showUPSDetails) {
                this.singleCarrierDetUPSRet = data[0];
                this.returnAccName = this.singleCarrierDetUPSRet.FriendlyName;
                this.returnAccNum = this.singleCarrierDetUPSRet.accountno;
              } else if (this.showFedExDetails) {
                this.singleCarrierDetFedExRet = data[0];
                this.returnAccName = this.singleCarrierDetFedExRet.FriendlyName;
                this.returnAccNum = this.singleCarrierDetFedExRet.accountno;
              }
            }
          }
        } else {
          this.serviceLevelDetails = data.Settings[0];
          this.accountInfo = data.AccountInfo;
          this.accountData = data.AccountInfo;
          this.ei_on = data.Ei_on;
          this.carrierShortName = data.Settings[0]?.carrierShortName;
          this.carrierKey = data.Settings[0]?.carrier_key;
          this.showAPISection = data.Settings[0]?.IsAPISectionVisible;
          if (this.showAPISection) {
            this.activateCarrWebServ = data.Settings[0]?.ActivateCarrierAPI;
            this.activateCarrWebServ_Enabled = data.Settings[0]?.ActivateCarrierAPI_enable == 1 ? true : false;
          } else {
            this.activateCarrWebServ = false;
            this.activateCarrWebServ_Enabled = false;
          }
          if (this.serviceLevelDetails.outbound_account_id !== null && this.serviceLevelDetails.outbound_account_id !== undefined) {
            if (this.accountInfo !== "" && this.accountInfo !== undefined) {
              this.accountDetailsOut = this.accountInfo.filter((obj) => obj.account_id == this.serviceLevelDetails.outbound_account_id);
              if (this.accountDetailsOut.length !== 0) {
                this.selectedAccountOut = this.accountDetailsOut[0].account_id;
                this.retOrOutbound = "OutBound";
                this.accountSelectedOut();
              }
              this.accountDetailsRet = this.accountInfo.filter((obj) => obj.account_id == this.serviceLevelDetails.return_account_id);
              if (this.accountDetailsRet.length !== 0) {
                this.selectedAccountRet = this.accountDetailsRet[0].account_id;
                this.retOrOutbound = "Return";
                this.accountSelectedRet();
              }
            }
          }
          this.apiinterfaceSetting = this.serviceLevelDetails.API_Interface_Setting;
          this.domesticServiceLevel = this.serviceLevelDetails.Domestic;
          this.freightCarrierServiceLevel = this.serviceLevelDetails.Freight;
          this.serviceLevelCode = this.serviceLevelDetails.RLP_Service_Level_Code;
          this.orderReqCarrierCode = this.serviceLevelDetails.ccode;
          this.orderReqServiceLevelCode = this.serviceLevelDetails.slevel;
          this.thirdPartyBillOut = this.serviceLevelDetails.bill_3rd;
          this.thirdPartyBillRet = this.serviceLevelDetails.bill_3rd_rtn;
          this.thirdPartyBillOut_Enabled = this.serviceLevelDetails.bill_3rd_enable == 1 ? false : true;
          this.thirdPartyBillRet_Enabled = this.serviceLevelDetails.bill_3rd_rtn_enable == 1 ? false : true;
          this.thirdPartyAccNoOut = this.serviceLevelDetails.alt_accountno1;
          this.thirdPartyAccNoRet = this.serviceLevelDetails.alt_accountno1_rtn;
          this.thirdPartyAccNoOutChange();
          this.thirdPartyAccNoRetChange();
          this.upsAPIThirdPartyPostalCode = this.serviceLevelDetails.ups_zip;
          this.upsAPIThirdPartyPostalCodeRet = this.serviceLevelDetails.ups_zip_rtn;
          this.upsAPIThirdParAccCountryCode = this.serviceLevelDetails.ups_country;
          this.upsAPIThirdParAccCountryCodeRet = this.serviceLevelDetails.ups_country_rtn;
          this.activeServiceLevel = this.serviceLevelDetails.Active == 1 ? true : false;
          this.activeServiceLevel_Enabled = this.serviceLevelDetails.Active_enable == 1 ? true : false;
          this.domesticServiceLevel = this.serviceLevelDetails.Domestic == 1 ? true : false;
          this.domesticServiceLevel_Enabled = this.serviceLevelDetails.Domestic_enable == 1 ? true : false;
        }
        if (this.carrierShortName == "UPS") {
          this.textAPIIntegration = "UPS";
          this.showUPSDetails = true;
          this.showNonUPSDetails = false;
          this.showFedExDetails = false;
          this.showUSPSDetails = false;
        } else if (this.carrierShortName == "FedEx") {
          this.textAPIIntegration = "FedEx";
          if (this.showAPISection) {
            this.showFedExDetails = true;
            this.showNonUPSDetails = false;
          } else {
            this.showNonUPSDetails = true;
            this.showFedExDetails = false;
          }
          this.showUPSDetails = false;
          this.showUSPSDetails = false;
        } else if (this.carrierShortName == "USPS") {
          this.textAPIIntegration = "USPS";
          this.showUSPSDetails = true;
          this.showUPSDetails = false;
          this.showNonUPSDetails = false;
          this.showFedExDetails = false;
        } else {
          this.textAPIIntegration = "";
          this.showNonUPSDetails = true;
          this.showUPSDetails = false;
          this.showFedExDetails = false;
          this.showUSPSDetails = false;
        }
      }
    },
    //Create new account for Outbound Acc Details
    createAccountOutbound() {
      this.carrierCompCalled = true;
      this.compName = "newCarrier";
      this.accountType = "";
      this.accountType = "OutBound";
    },
    //Create new account for Return Acc Details
    createAccountReturn() {
      this.carrierCompCalled = true;
      this.compName = "newCarrier";
      this.accountType = "";
      this.accountType = "Return";
    },
    //Edit Account Details Outbound
    editAccountOutbound() {
      this.carrierCompCalled = true;
      this.compName = "editCarrier";
      this.accountType = "";
      this.accountType = "OutBound";
      if (this.showUPSDetails) {
        this.singleCarrierDet = [];
        this.singleCarrierDet = this.singleCarrierDetUPSOut;
      } else if (this.showFedExDetails) {
        this.singleCarrierDet = [];
        this.singleCarrierDet = this.singleCarrierDetFedExOut;
      }
    },
    //Edit Account Details Return
    editAccountReturn() {
      this.carrierCompCalled = true;
      this.compName = "editCarrier";
      this.accountType = "";
      this.accountType = "Return";
      if (this.showUPSDetails) {
        this.singleCarrierDet = [];
        this.singleCarrierDet = this.singleCarrierDetUPSRet;
      } else if (this.showFedExDetails) {
        this.singleCarrierDet = [];
        this.singleCarrierDet = this.singleCarrierDetFedExRet;
      }
    },
    //Cancel button click event for Page
    onClickCancel() {
      this.newAccount = false;
      this.$router.push("/app-service-level");
      this.resetFunction();
    },
    //Close button click event for New Account Popup
    closeDialog() {
      this.newAccount = false;
      this.accountName = "";
      this.accAllowforMultiProj = false;
      this.accountNumber = "";
      this.upsPortalName = "";
      this.upsPortalPass = "";
      this.upsAccessLicense = "";
      this.title = "";
      this.firstName = "";
      this.lastName = "";
      this.company = "";
      this.address1 = "";
      this.address2 = "";
      this.city = "";
      this.state = "";
      this.zip = "";
      this.selectedCountry = "";
      this.phone = "";
      this.email = "";
    },
    //Account selection
    async accountSelectedOut(accountSet) {
      if (this.activateCarrWebServ) {
        if (!accountSet) {
          this.outboundAccName = "";
          this.outboundAccNum = "";
          this.thirdPartyBillOut = false;
        }
        this.thirdPartyAccNoOutChange();
        if (this.selectedAccountOut !== null && this.selectedAccountOut !== undefined) {
          this.singleCarrierDetUPSOut = [];
          this.singleCarrierDetFedExOut = [];
          this.accountDetailsOut = [];
          this.accountDetailsOut = this.accountData.filter((obj) => obj.account_id === this.selectedAccountOut);
          this.outBoundAccID = this.accountDetailsOut[0].account_id;
          let obj = {
            UserId: this.userId,
            pass: 4,
            account_id: parseInt(this.outBoundAccID),
            acct_name: "",
            carrier_key: 0,
            proj_key: 0,
            bu_key: 0,
            accountno: "",
            client_id: "",
            client_secret: "",
            status: true,
          };
          let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
          if (data.message != "NA") {
            this.accountSelectedDetailsOut = true;
            this.newAccOutbound = true;
            if (this.showUPSDetails) {
              this.singleCarrierDetUPSOut = data[0];
              this.outboundAccName = this.singleCarrierDetUPSOut.FriendlyName;
              this.outboundAccNum = this.singleCarrierDetUPSOut.accountno;
            } else if (this.showFedExDetails) {
              this.singleCarrierDetFedExOut = data[0];
              this.outboundAccName = this.singleCarrierDetFedExOut.FriendlyName;
              this.outboundAccNum = this.singleCarrierDetFedExOut.accountno;
            }
          }
        } else {
          this.accountSelectedDetailsOut = false;
        }
      } else {
        this.carrierServiceAPIChange();
      }
    },
    //Account Selection
    async accountSelectedRet(accountSet) {
      if (this.activateCarrWebServ) {
        if (!accountSet) {
          this.returnAccName = "";
          this.returnAccNum = "";
          this.thirdPartyBillRet = false;
        }
        this.thirdPartyAccNoRetChange();
        if (this.selectedAccountRet !== null && this.selectedAccountRet !== undefined) {
          this.singleCarrierDetUPSRet = [];
          this.singleCarrierDetFedExRet = [];
          this.accountDetailsRet = [];
          this.accountDetailsRet = this.accountData.filter((obj) => obj.account_id === this.selectedAccountRet);
          this.returnAccID = this.accountDetailsRet[0].account_id;
          let obj = {
            UserId: this.userId,
            pass: 4,
            account_id: parseInt(this.returnAccID),
            acct_name: "",
            carrier_key: 0,
            proj_key: 0,
            bu_key: 0,
            accountno: "",
            client_id: "",
            client_secret: "",
            status: true,
          };
          let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
          if (data.message != "NA") {
            this.accountSelectedDetailsRet = true;
            this.newAccReturn = true;
            if (this.showUPSDetails) {
              this.singleCarrierDetUPSRet = data[0];
              this.returnAccName = this.singleCarrierDetUPSRet.FriendlyName;
              this.returnAccNum = this.singleCarrierDetUPSRet.accountno;
            } else if (this.showFedExDetails) {
              this.singleCarrierDetFedExRet = data[0];
              this.returnAccName = this.singleCarrierDetFedExRet.FriendlyName;
              this.returnAccNum = this.singleCarrierDetFedExRet.accountno;
            }
          }
        } else {
          this.accountSelectedDetailsRet = true;
        }
      } else {
        this.carrierServiceAPIChange();
      }
    },
    //Enabled third Party Acc number to fill data for Outbound
    thirdPartyAccNoOutChange() {
      if (!this.thirdPartyBillOut) {
        this.thirdPartyAccNoOut = "";
        this.upsAPIThirdParPostal = "";
        this.upsAPIThirdParAccCountryCode = "";
      } else {
        this.upsAPIThirdParAccCountryCode = "USA";
      }
      this.thirdPartyAccNoOut_Enabled = this.thirdPartyBillOut;
      this.upsAPIThirdPartyPostalCode_Enabled = this.thirdPartyBillOut;
    },
    //Enabled third Party Acc number to fill data for Return
    thirdPartyAccNoRetChange() {
      if (!this.thirdPartyBillRet) {
        this.thirdPartyAccNoRet = "";
        this.upsAPIThirdParPostalRet = "";
        this.upsAPIThirdParAccCountryCodeRet = "";
      } else {
        this.upsAPIThirdParAccCountryCodeRet = "USA";
      }
      this.thirdPartyAccNoRet_Enabled = this.thirdPartyBillRet;
      this.upsAPIThirdPartyPostalCodeRet_Enabled = this.thirdPartyBillRet;
    },
    //Submit update details of service level
    editServiceLevel() {
      if (this.showNonUPSDetails) {
        if (this.$refs.carrierXilyticInfoForm.validate()) {
          this.formValidated = true;
        }
      } else if (this.showUPSDetails) {
        if (!this.activateCarrWebServ && this.$refs.carrierXilyticInfoForm.validate()) {
          this.formValidated = true;
        } else if (this.$refs.UPSForm.validate() && this.$refs.carrierXilyticInfoForm.validate()) {
          if (this.thirdPartyBillOut || this.thirdPartyBillRet) {
            if (this.thirdPartyAccNoOut !== "" || this.thirdPartyAccNoRet !== "") {
              this.formValidated = true;
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "3rd Party Account Number is required please fill it",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.formValidated = false;
            }
          } else {
            this.formValidated = true;
          }
        }
      } else if (this.showFedExDetails) {
        if (!this.activateCarrWebServ && this.$refs.carrierXilyticInfoForm.validate()) {
          this.formValidated = true;
        } else if (this.$refs.carrierXilyticInfoForm.validate() && this.$refs.fedExForm.validate()) {
          if (this.thirdPartyBillOut || this.thirdPartyBillRet) {
            if (this.thirdPartyAccNoOut !== "" || this.thirdPartyAccNoRet !== "") {
              this.formValidated = true;
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "3rd Party Account Number is required please fill it",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.formValidated = false;
            }
          } else {
            this.formValidated = true;
          }
        }
      } else if (this.showUSPSDetails) {
        if (this.$refs.USPSForm.validate() && this.$refs.carrierXilyticInfoForm.validate()) {
          this.formValidated = true;
        }
      } else {
        this.formValidated = false;
      }
      if (this.formValidated) {
        this.accountId = 0;
        this.sl_id = 0;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let serviceLevelUpdateObj = {
          InJson: JSON.stringify({
            ord_code: this.serviceLevelsData.ord_code,
            account_id: this.accountId,
            proj_key: parseInt(this.serviceLevelsData.projKey),
            bu_key: parseInt(this.serviceLevelsData.business),
            sbu_key: parseInt(this.serviceLevelsData.sbu_id),
            User_id: parseInt(this.userId),
            sl_key: this.sl_id,
            slbu_id: parseInt(this.serviceLevelsData.slbu_id),
            accountno: this.outboundAccNum,
            accountno2: this.outboundAccName,
            bill_3rd: this.thirdPartyBillOut ? 1 : 0,
            alt_accountno1: this.thirdPartyAccNoOut,
            alt_accountno2: "",
            return_services: "",
            ups_zip: this.upsAPIThirdPartyPostalCode,
            ups_countrycode: this.upsAPIThirdParAccCountryCode,
            fedex_ip: "",
            slevel: this.orderReqServiceLevelCode,
            ccode: this.orderReqCarrierCode,
            active: this.activeServiceLevel ? 1 : 0,
            accountno_rtn: this.returnAccNum,
            accountno2_rtn: this.returnAccName,
            bill_3rd_rtn: this.thirdPartyBillRet ? 1 : 0,
            alt_accountno1_rtn: this.thirdPartyAccNoRet,
            alt_accountno2_rtn: "",
            ups_zip_rtn: this.upsAPIThirdPartyPostalCodeRet,
            ups_countrycode_rtn: this.upsAPIThirdParAccCountryCodeRet,
            fedex_ip_rtn: "",
            api_active: this.activateCarrWebServ ? 1 : 0,
            default_sl: this.default_sl ? 1 : 0,
            outbound_account_id: this.accountDetailsOut.length == 0 ? 0 : this.accountDetailsOut[0].account_id,
            return_account_id: this.accountDetailsRet.length == 0 ? 0 : this.accountDetailsRet[0].account_id,
          }),
        };
        this.axios
          .post("/cp/servicelevels_upsert", serviceLevelUpdateObj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              this.$router.push("/app-service-level");
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //To close the components
    closeComp() {
      this.name = "";
      this.carrierCompCalled = false;
    },
    //Carrier Added
    async carrierAdded(newCarrierID) {
      this.newCarrierID = newCarrierID;
      this.compName = "";
      this.carrierCompCalled = false;
      this.getServiceLevelDetails(newCarrierID);
    },
    //Carrier Updated
    async carrierUpdated(editCarrierID) {
      this.compName = "";
      this.carrierCompCalled = false;
      if (this.accountType == "OutBound") {
        this.accountDetailsOut = this.accountData.filter((obj) => obj.account_id === editCarrierID);
        this.selectedAccountOut = this.accountDetailsOut[0].account_id;
        let obj = {
          UserId: this.userId,
          pass: 4,
          account_id: parseInt(editCarrierID),
          acct_name: "",
          carrier_key: 0,
          proj_key: 0,
          bu_key: 0,
          accountno: "",
          client_id: "",
          client_secret: "",
          status: true,
        };
        let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
        if (data.message != "NA") {
          if (this.showUPSDetails) {
            this.singleCarrierDetUPSOut = data[0];
            this.outboundAccName = this.singleCarrierDetUPSOut.FriendlyName;
            this.outboundAccNum = this.singleCarrierDetUPSOut.accountno;
          } else if (this.showFedExDetails) {
            this.singleCarrierDetFedExOut = data[0];
            this.outboundAccName = this.singleCarrierDetFedExOut.FriendlyName;
            this.outboundAccNum = this.singleCarrierDetFedExOut.accountno;
          }
        }
      } else if (this.accountType == "Return") {
        this.accountDetailsRet = this.accountData.filter((obj) => obj.account_id === editCarrierID);
        this.selectedAccountRet = this.accountDetailsRet[0].account_id;
        let obj = {
          UserId: this.userId,
          pass: 4,
          account_id: parseInt(editCarrierID),
          acct_name: "",
          carrier_key: 0,
          proj_key: 0,
          bu_key: 0,
          accountno: "",
          client_id: "",
          client_secret: "",
          status: true,
        };
        let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
        if (data.message != "NA") {
          if (this.showUPSDetails) {
            this.singleCarrierDetUPSRet = data[0];
            this.returnAccName = this.singleCarrierDetUPSRet.FriendlyName;
            this.returnAccNum = this.singleCarrierDetUPSRet.accountno;
          } else if (this.showFedExDetails) {
            this.singleCarrierDetFedExRet = data[0];
            this.returnAccName = this.singleCarrierDetFedExRet.FriendlyName;
            this.returnAccNum = this.singleCarrierDetFedExRet.accountno;
          }
        }
      }
    },
    //Change in Carrier Service Level API
    carrierServiceAPIChange() {
      if (this.activateCarrWebServ) {
        this.accountSelectedDetailsOut = false;
        this.thirdPartyAccNoOut_Enabled = false;
        this.upsAPIThirdPartyPostalCode_Enabled = false;
        this.accountSelectedDetailsRet = false;
        this.thirdPartyAccNoRet_Enabled = false;
        this.upsAPIThirdPartyPostalCodeRet_Enabled = false;
        this.thirdPartyBillOut = false;
        this.thirdPartyBillRet = false;
        this.newAccOutbound = true;
        this.newAccReturn = true;
      } else {
        this.accountSelectedDetailsOut = false;
        this.thirdPartyAccNoOut_Enabled = false;
        this.upsAPIThirdPartyPostalCode_Enabled = false;
        this.accountSelectedDetailsRet = false;
        this.thirdPartyAccNoRet_Enabled = false;
        this.upsAPIThirdPartyPostalCodeRet_Enabled = false;
        this.selectedAccountOut = "";
        this.outboundAccName = "";
        this.outboundAccNum = "";
        this.thirdPartyBillOut = false;
        this.thirdPartyAccNoOut = "";
        this.selectedAccountRet = "";
        this.returnAccName = "";
        this.returnAccNum = "";
        this.thirdPartyBillRet = false;
        this.thirdPartyAccNoRet = "";
        this.newAccOutbound = false;
        this.newAccReturn = false;
        if (this.showFedExDetails) {
          this.$refs.fedExForm.resetValidation();
        } else if (this.showUPSDetails) {
          this.$refs.UPSForm.resetValidation();
        }
        if (!this.showFedExDetails) {
          this.upsAPIThirdPartyPostalCode = "";
          this.upsAPIThirdParAccCountryCode = "";
          this.upsAPIThirdPartyPostalCodeRet = "";
          this.upsAPIThirdParAccCountryCodeRet = "";
        }
      }
    },
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList != null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter != undefined && filter != null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    carrierMasterComp,
  },
};
